import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Img,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PageBg } from "../../../../utilities/components/Shared.elements";

const Error404 = ({ errorMessage }) => {
  const navigate = useNavigate();

  return (
    <PageBg>
      <Stack spacing={"10px"} justify={"space-evenly"}>
        <Center>
          <SimpleGrid columns={{ base: 1, md: 2 }}>
            <Img
              maxH="400px"
              src={
                "https://res.cloudinary.com/wow-importer/image/upload/v1657561830/13_ouxv0d.png"
              }
            />
            <Stack justify={"center"} align="center" spacing={"20px"}>
              <Heading
                fontSize={{ base: "xl", sm: "2xl", md: "3xl", xl: "4xl" }}
              >
                {errorMessage}
              </Heading>
              <Wrap justify={"center"} align="center">
                {[
                  {
                    to: "/",
                    text: "Go Back to Home",
                    color: "#FFF",
                    bg: "#F54748",
                  },
                  {
                    to: "/products",
                    text: "Go Back to products",
                    color: "#FFF",
                    bg: "#F54748",
                  },
                ]?.map((item, index) => (
                  <Box key={index}>
                    <Button
                      onClick={() => navigate(item.to)}
                      bg={item.bg}
                      color={item.color}
                      _hover={{
                        opacity: "0.9",
                      }}
                      fontSize={"22px"}
                    >
                      {item.text}
                    </Button>
                  </Box>
                ))}
              </Wrap>
            </Stack>
          </SimpleGrid>
        </Center>
      </Stack>
    </PageBg>
  );
};

export default Error404;
