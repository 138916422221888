import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainNavigation from "../utilities/components/navigation/mainNavigation/MainNavigation";
const Home = React.lazy(() => import("../home/Home"));
const ProductsSection = React.lazy(() =>
  import("../products/productList/ProductsSection")
);

const ProductDetail = React.lazy(() =>
  import("../products/productDetail/ProductDetail")
);

const About = React.lazy(() => import("../about/About"));

const Contact = React.lazy(() => import("../contact/Contact"));

const NoAuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainNavigation />}>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductsSection />} />
        <Route
          path="/products-list/page=:filter"
          element={<ProductsSection />}
        />
        <Route
          path="/products/searchQuery=:searchQuery"
          element={<ProductsSection />}
        />
        <Route path="/products/:catId" element={<ProductsSection />} />
        <Route
          path="/products/:catId/page=:filter"
          element={<ProductsSection />}
        />
        <Route path="/products/:catId/:id" element={<ProductDetail />} />
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />{" "}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default NoAuthRoutes;
