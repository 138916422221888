import { Box, HStack, Stack, StackDivider, Text } from "@chakra-ui/react";
import React from "react";
import { useCart } from "../../utilities/hooks/cart-hook";

const OrderShippingInfo = () => {
  const { cartProducts } = useCart();

  return (
    <HStack>
      <Box maxW="0px" flexGrow={"1"} />
      <Stack minW={"330px"} maxW={"330px"} fontSize={"18px"}>
        <HStack>
          <Text flexGrow="1" fontWeight={"semibold"}>
            Subtotals:
          </Text>
          <Text>
            ${" "}
            {cartProducts
              ?.reduce((acc, item) => acc + item?.quantity * item?.price, 0)
              ?.toFixed(2)}
          </Text>
        </HStack>
        <HStack>
          <Text flexGrow="1" fontWeight={"semibold"}>
            Tax:
          </Text>
          <Text>
            ${" "}
            {(
              cartProducts?.reduce(
                (acc, item) => acc + item?.quantity * item?.price,
                0
              ) * 0.15
            )?.toFixed(2)}
          </Text>
        </HStack>
        <Stack divider={<StackDivider borderColor="gray.400" />}>
          <HStack>
            <Text flexGrow="1" fontWeight={"semibold"}>
              Shipping:
            </Text>
            <Text>$ 5 </Text>
          </HStack>
          <HStack>
            <Text flexGrow="1" fontWeight={"semibold"}>
              Total:
            </Text>
            <Text>
              ${" "}
              {(
                cartProducts?.reduce(
                  (acc, item) => acc + item?.quantity * item?.price,
                  0
                ) *
                  1.15 +
                5
              )?.toFixed(2)}
            </Text>
          </HStack>
        </Stack>
      </Stack>
    </HStack>
  );
};

export default OrderShippingInfo;
