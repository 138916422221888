import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/auth-hook";

export default function AdminBreadCrumb() {
  //Hook
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { catId, colId, id } = useParams();
  console.log({ catId });

  //Function
  const path = pathname?.split("/")?.filter((x) => x);
  const { token } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const catColData = useQuery(
    catId ? [`categoryData`, catId] : colId ? [`collectionData`, colId] : "",
    async () =>
      await axios.get(
        catId
          ? `${process.env.REACT_APP_BACKEND_URL}categories/${catId}`
          : colId
          ? `${process.env.REACT_APP_BACKEND_URL}collections/${colId}`
          : ``,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!catId || !!colId,
    }
  );

  console.log({ catColData });
  const productData = useQuery(
    [`productData`, id],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}products/${id}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
    }
  );

  //JSx
  return (
    <Box minH="24px">
      {(catColData?.isIdle ||
        (!catColData?.isFetching && catColData?.isSuccess)) &&
        (productData?.isIdle ||
          (!productData?.isFetching && productData?.isSuccess)) && (
          <Breadcrumb
            pl={"2%"}
            spacing="8px"
            separator={<ChevronRightIcon color="gray.500" />}
          >
            {path.map((name, index) => {
              const routeTo = `/${path.slice(0, index + 1).join("/")}`;
              const isFirst = index === 0;
              const isLast = index === path.length - 1;
              console.log(`index${index}`, name === catId?.toString());
              console.log(`index${index}`, name);
              console.log(`index${index}`, catId);

              return isFirst ? (
                <div />
              ) : isLast ? (
                <BreadcrumbItem key={index}>
                  <Text color={"#868686"}>
                    {name === catId?.toString()
                      ? catColData?.data?.data?.data?.name
                      : name === colId?.toString()
                      ? catColData?.data?.data?.data?.collectionName
                      : name === id?.toString()
                      ? productData?.data?.data?.data?.productName
                      : name}
                  </Text>
                </BreadcrumbItem>
              ) : (
                <BreadcrumbItem key={index}>
                  <BreadcrumbLink onClick={() => navigate(routeTo)}>
                    {name === catId?.toString()
                      ? catColData?.data?.data?.data?.name
                      : name === colId?.toString()
                      ? catColData?.data?.data?.data?.collectionName
                      : name === id?.toString()
                      ? productData?.data?.data?.data?.productName
                      : name}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>
        )}
    </Box>
  );
}
