import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
  InputGroup,
  InputRightElement,
  Img,
  Center,
  HStack,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  VStack,
  Wrap,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useLang } from "../../utilities/hooks/lang-hook";
import { Logo } from "../../utilities/components/Logo";
import RegisterModal from "./RegisterModal";
import { BiHide, BiShow } from "react-icons/bi";
import { GradientButton } from "../../utilities/components/Shared.elements";

export default function LoginModal({
  isOpen,
  onClose,
  register,
  onRegister,
  redirectURL,
}) {
  const { login } = useAuth();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { isAmh, changeLang } = useLang();
  const [isSmallerThan360] = useMediaQuery("(max-width: 360px)");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const loginMutation = useMutation(
    async (newLogin) =>
      await axios.post(process.env.REACT_APP_BACKEND_URL + "login", newLogin, {
        headers,
      }),
    {
      retry: false,
    }
  );
  console.log({ loginMutation });

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required").min(6, "Too Short!"),
  });

  const onSubmit = async ({ email, password }) => {
    try {
      loginMutation.mutate(
        {
          email,
          password,
        },
        {
          onSuccess: (responseData) => {
            if (responseData?.data?.token) {
              login(
                responseData?.data?.token,
                responseData?.data?.user?.role,
                responseData?.data?.user
              );
              onClose();
              redirectURL
                ? navigate(redirectURL)
                : responseData?.data?.user?.role === "admin"
                ? navigate("/dashboard/data")
                : navigate("/");
              toast({
                title: "Welcome to Lidya Spices.",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Login failed.",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          },
          onError: (responseData) => {
            console.log({ responseData });

            toast({
              title:
                (responseData?.message === "Network Error" &&
                  responseData?.message) ||
                responseData?.response?.data?.message ||
                responseData?.response?.data ||
                "Error on login",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };

  return (
    <Modal
      size={register ? { base: "5xl" } : { base: "sm", sm: "md", md: "lg" }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <Box borderRadius={"50px"}>
          {register ? (
            <RegisterModal onRegister={onRegister} />
          ) : (
            <Flex
              borderRadius={"30px"}
              minH={{ base: "60vh", md: "70vh" }}
              align={"center"}
              justify={"center"}
            >
              <Stack
                spacing={0}
                mx={"auto"}
                maxW={"lg"}
                py={{ base: 5, sm: 9 }}
                px={{ base: 2, sm: 6 }}
              >
                <Stack align={"center"}>
                  <Center pb="20px">
                    <VStack spacing={"22px"}>
                      <Logo />
                    </VStack>
                  </Center>
                </Stack>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(values) => {
                    return (
                      <Box
                        rounded={"lg"}
                        alignSelf="center"
                        minW={{ base: "100%", md: "380px" }}
                        py={8}
                        px={{ base: 5, sm: 8 }}
                      >
                        <Form>
                          <Stack spacing={8}>
                            <Field name="email">
                              {({ field, form }) => (
                                <FormControl
                                  minW={
                                    isSmallerThan360
                                      ? "100%"
                                      : { base: "300px", sm: "360px" }
                                  }
                                  maxW={
                                    isSmallerThan360
                                      ? "100%"
                                      : { base: "300px", sm: "360px" }
                                  }
                                  id="email"
                                >
                                  {/* <FormLabel>Email address</FormLabel> */}
                                  <Input
                                    {...field}
                                    placeholder="Email"
                                    type="email"
                                  />
                                  {values?.errors?.email &&
                                    values?.touched?.email && (
                                      <Text color="red">
                                        {values?.errors?.email}
                                      </Text>
                                    )}
                                </FormControl>
                              )}
                            </Field>
                            <Field name="password">
                              {({ field, form }) => (
                                <FormControl id="password">
                                  {/* <FormLabel>Password</FormLabel> */}
                                  <InputGroup size="md">
                                    <Input
                                      {...field}
                                      id="password"
                                      placeholder="password"
                                      type={showPassword ? "text" : "password"}
                                    />
                                    <InputRightElement width="4.5rem">
                                      <Button
                                        h="1.75rem"
                                        size="sm"
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                      >
                                        {showPassword ? (
                                          <BiHide size="1.4rem" />
                                        ) : (
                                          <BiShow size="1.4rem" />
                                        )}{" "}
                                      </Button>
                                    </InputRightElement>
                                  </InputGroup>
                                  {values?.errors?.password &&
                                    values?.touched?.password && (
                                      <Text color="red">
                                        {values?.errors?.password}
                                      </Text>
                                    )}
                                </FormControl>
                              )}
                            </Field>
                            <Stack spacing={10}>
                              <GradientButton
                                width="100%"
                                disabled={
                                  !(
                                    values.isValid &&
                                    values.dirty &&
                                    !loginMutation.isLoading
                                  )
                                }
                                type={
                                  !loginMutation?.isLoading
                                    ? "submit"
                                    : "button"
                                }
                                color={"white"}
                                _hover={{
                                  bg: "#FFF",
                                  color: "#F55A5A",
                                }}
                                py={6}
                                border={"1px solid #F55A5A"}
                              >
                                {loginMutation?.isLoading ? (
                                  <Center>
                                    <Spinner />
                                  </Center>
                                ) : (
                                  "Login"
                                )}
                              </GradientButton>
                              <Wrap
                                justify={"center"}
                                spacing="6px"
                                fontSize={"18px"}
                              >
                                <Text>Not Registered Yet? </Text>
                                <Link to="#" onClick={() => onRegister(true)}>
                                  <Text py={1} color="#f16162">
                                    Create an Account
                                  </Text>
                                </Link>
                              </Wrap>
                            </Stack>
                          </Stack>
                        </Form>
                      </Box>
                    );
                  }}
                </Formik>
              </Stack>
            </Flex>
          )}
        </Box>
      </ModalContent>
    </Modal>
  );
}
