import React from "react";
import { FiUsers } from "react-icons/fi";
import { MdCategory, MdDashboard } from "react-icons/md";
import {
  MdOutlineProductionQuantityLimits,
  MdOutlineAccountCircle,
  MdOutlineCelebration,
  MdOutlineCollections,
  MdProductionQuantityLimits,
} from "react-icons/md";
const EditFlashSale = React.lazy(() =>
  import("../admin/flashSales/EditFlashSale/EditFlashSale")
);
const ManageBanners = React.lazy(() =>
  import("../admin/banners/ManageBanners")
);
const AddFlashSale = React.lazy(() =>
  import("../admin/flashSales/AddFlashSale/AddFlashSale")
);
const ManageFlashSales = React.lazy(() =>
  import("../admin/flashSales/ManageFlashSales")
);

const AddProduct = React.lazy(() =>
  import("../admin/products/addProduct/AddProduct")
);

const Dashboard = React.lazy(() => import("../admin/dashboard/Dashboard"));

const ManageProducts = React.lazy(() =>
  import("../admin/products/ManageProducts")
);

const ManageCategories = React.lazy(() =>
  import("../admin/categories/ManageCategories")
);

const ManageUsers = React.lazy(() => import("../admin/users/ManageUsers"));

const ManageOrders = React.lazy(() => import("../admin/orders/ManageOrders"));

{
  /* <Route path="/dashboard" element={<Dashboard />} />
<Route path="/products" element={<ManageProducts />} />
<Route path="/categories" element={<ManageCategories />} />
<Route path="/users" element={<ManageUsers />} />
<Route path="/orders" element={<ManageOrders />} /> */
}

export const adminRoutes = [
  {
    path: "/dashboard/data",
    element: <Dashboard />,
    name: "Dashboard",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/dashboard/products",
    element: <ManageProducts />,
    name: "Products",
    icon: <MdOutlineProductionQuantityLimits size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/dashboard/products/add-product",
    element: <AddProduct />,
    sideBarVisible: false,
  },
  {
    path: "/dashboard/categories",
    element: <ManageCategories />,
    name: "Categories",
    icon: <MdCategory size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/dashboard/banners",
    element: <ManageBanners />,
    name: "Banners",
    icon: <MdCategory size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/dashboard/flash-sale",
    element: <ManageFlashSales />,
    name: "Flash Sales",
    icon: <MdCategory size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/dashboard/flash-sale/add-flash-sale",
    element: <AddFlashSale />,
    sideBarVisible: false,
  },
  {
    path: "/dashboard/flash-sale/:id",
    element: <EditFlashSale />,
    sideBarVisible: false,
  },
  {
    path: "/dashboard/orders",
    element: <ManageOrders />,
    name: "Orders",
    icon: <MdCategory size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/dashboard/users",
    element: <ManageUsers />,
    name: "Users",
    icon: <FiUsers size={"1.5em"} />,
    sideBarVisible: true,
  },
];
