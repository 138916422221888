import React from "react";
import {
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  HStack,
  Box,
  Button,
  Text,
  Stack,
  Divider,
  Img,
  Center,
} from "@chakra-ui/react";
import { useCart } from "../../utilities/hooks/cart-hook";

const OrderCart = () => {
  const { cartProducts } = useCart();
  return (
    <Stack
      overflowY={"auto"}
      maxH="200px"
      minW={"330px"}
      maxW={{ base: "320px", sm: "330px" }}
    >
      {cartProducts?.map((cartItem, index) => (
        <HStack key={cartItem?.product}>
          <Img objectFit={"cover"} w="75px" h="70px" src={cartItem?.image} />
          <Stack flexGrow={"1"}>
            <Text fontSize={"14px"}>{cartItem?.name}</Text>
            <Text fontSize={"14px"} color="#B2B2B2">
              {cartItem?.color}
            </Text>
            <Text color="#000000" fontSize={"14px"}>
              {cartItem?.price}
            </Text>
          </Stack>
          <HStack spacing={"10px"}>
            <HStack>
              <Text fontSize={"14px"}>{cartItem?.quantity}</Text>
            </HStack>
          </HStack>
        </HStack>
      ))}
    </Stack>
  );
};

export default OrderCart;
