import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainNavigation from "../utilities/components/navigation/mainNavigation/MainNavigation";
import { useCart } from "../utilities/hooks/cart-hook";
import CreditCardPage from "../payment/creditCard/CreditCardPage";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Error404 from "../products/productDetail/components/errorPages/Error404";

const Home = React.lazy(() => import("../home/Home"));
const ProductsSection = React.lazy(() =>
  import("../products/productList/ProductsSection")
);

const About = React.lazy(() => import("../about/About"));

const Contact = React.lazy(() => import("../contact/Contact"));

const ProductDetail = React.lazy(() =>
  import("../products/productDetail/ProductDetail")
);

const Cart = React.lazy(() => import("../cart/Cart"));

const ShippingInformation = React.lazy(() =>
  import("../shipping/ShippingInformation")
);

const PaymentInformation = React.lazy(() =>
  import("../payment/PaymentInformation/PaymentInformation")
);

const MyOrders = React.lazy(() => import("../orders/MyOrders"));

const CustomerRoutes = ({ stripeapi }) => {
  const { cartProducts } = useCart();

  return (
    <Routes>
      <Route path="/" element={<MainNavigation />}>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductsSection />} />
        <Route
          path="/products-list/page=:filter"
          element={<ProductsSection />}
        />
        <Route
          path="/products/searchQuery=:searchQuery"
          element={<ProductsSection />}
        />
        <Route path="/products/:catId" element={<ProductsSection />} />
        <Route
          path="/products/:catId/page=:filter"
          element={<ProductsSection />}
        />
        <Route path="/products/:catId/:id" element={<ProductDetail />} />
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />{" "}
        <Route path="/cart" element={<Cart />} />
        <Route path="/orders" element={<MyOrders />} />
        {cartProducts?.length > 0 && (
          <Route path="/cart/shipping" element={<ShippingInformation />} />
        )}
        {cartProducts?.length > 0 && (
          <Route
            path="/cart/shipping/payment"
            element={<PaymentInformation />}
          />
        )}
        {!stripeapi?.isLoading &&
          stripeapi?.isSuccess &&
          cartProducts?.length > 0 && (
            <Route
              path="/cart/shipping/payment/credit-card"
              element={
                stripeapi?.data?.data?.stripeAPiKey && (
                  <Elements
                    stripe={loadStripe(stripeapi?.data?.data?.stripeAPiKey)}
                  >
                    {" "}
                    <CreditCardPage />{" "}
                  </Elements>
                )
              }
              exact
            />
          )}{" "}
        <Route
          path="*"
          element={<Error404 errorMessage={"Unknown Page"} />}
        />{" "}
      </Route>
    </Routes>
  );
};

export default CustomerRoutes;
