import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const CartContext = createContext();

export function useCart() {
  return useContext(CartContext);
}

export function CartProvider({ children }) {
  //Hook

  // declare usefull auth states

  const [cartProducts, setCartProducts] = useState([]);
  const [shippingInfo, setShippingInfo] = useState([]);

  console.log({ cartProducts });
  //Function

  const addProduct = useCallback((product, quantity) => {
    console.log({ product, quantity });
    if (quantity) {
      let existingEntries = JSON.parse(
        localStorage.getItem("lidyaSpicesCartData")
      );
      if (!existingEntries) existingEntries = { cartProducts: [] };

      const addedProduct = {
        name: product?.name,
        quantity: quantity,
        image: product?.images?.[0]?.url,
        price: product?.price,
        product: product?._id,
        description: product?.description,
        seller: product?.seller,
      };

      let productExists = existingEntries?.cartProducts.find(
        (i) => i.product == addedProduct?.product
      );

      let newList = [];

      productExists
        ? existingEntries?.cartProducts.map((i) =>
            i.product === productExists?.product
              ? newList.push(addedProduct)
              : newList.push(i)
          )
        : newList.push(...existingEntries?.cartProducts, addedProduct);

      setCartProducts(newList);

      localStorage.setItem(
        "lidyaSpicesCartData",
        JSON.stringify({ cartProducts: newList })
      );
    } else {
      setCartProducts(product);
      localStorage.setItem(
        "lidyaSpicesCartData",
        JSON.stringify({ cartProducts: product })
      );
    }
  }, []);

  const addShippingInfo = useCallback((shippingInfo) => {
    console.log({ shippingInfo });
    setShippingInfo(shippingInfo);
    localStorage.setItem(
      "lidyaSpicesShippingData",
      JSON.stringify({ shippingInfo })
    );
  }, []);

  const increaseQuantity = useCallback((product, quantity) => {
    let existingEntries = JSON.parse(
      localStorage.getItem("lidyaSpicesCartData")
    );
    if (!existingEntries) existingEntries = { cartProducts: [] };
    console.log({ quantity });

    let newList = [];

    const addedProduct = {
      name: product?.name,
      quantity: quantity,
      image: product?.image,
      price: product?.price,
      product: product?.product,
      description: product?.description,
    };

    existingEntries?.cartProducts.map((i) =>
      i.product === addedProduct?.product
        ? newList.push(addedProduct)
        : newList.push(i)
    );

    setCartProducts(newList);
    console.log({ newList });
    localStorage.setItem(
      "lidyaSpicesCartData",
      JSON.stringify({ cartProducts: newList })
    );
  }, []);

  const descreaseQuantity = useCallback((product, quantity) => {
    console.log({ quantity });
    let existingEntries = JSON.parse(
      localStorage.getItem("lidyaSpicesCartData")
    );
    if (!existingEntries) existingEntries = { cartProducts: [] };

    let newList = [];

    const addedProduct = {
      name: product?.name,
      quantity: quantity,
      image: product?.image,
      price: product?.price,
      product: product?.product,
      description: product?.description,
    };

    existingEntries?.cartProducts.map((i) =>
      i.product === addedProduct?.product
        ? newList.push(addedProduct)
        : newList.push(i)
    );

    setCartProducts(newList);
    console.log({ newList });
    localStorage.setItem(
      "lidyaSpicesCartData",
      JSON.stringify({ cartProducts: newList })
    );
  }, []);

  const removeCartItem = useCallback((product) => {
    let existingEntries = JSON.parse(
      localStorage.getItem("lidyaSpicesCartData")
    );
    if (!existingEntries) existingEntries = { cartProducts: [] };

    let newList = [];

    existingEntries?.cartProducts.map((i) =>
      i.product === product?.product ? console.log(null) : newList.push(i)
    );

    setCartProducts(newList);
    console.log({ newList });
    localStorage.setItem(
      "lidyaSpicesCartData",
      JSON.stringify({ cartProducts: newList })
    );
  }, []);

  // state.cartItems.filter((i) => i.product !== action.payload)

  const clearCart = useCallback(() => {
    setCartProducts([]);
    localStorage.setItem(
      "lidyaSpicesCartData",
      JSON.stringify({
        cartProducts,
      })
    );
  }, []);

  let cart, shipping;

  useEffect(() => {
    const storedProductData = JSON.parse(
      localStorage.getItem("lidyaSpicesCartData")
    );
    const storedShippingData = JSON.parse(
      localStorage.getItem("lidyaSpicesShippingData")
    );

    if (storedProductData) {
      if (storedProductData.cartProducts) {
        cart = addProduct(storedProductData.cartProducts, false);
      }
    } else {
      cart = addProduct([]);
    }
    console.log({ storedShippingData });
    if (storedShippingData && storedShippingData.shippingInfo) {
      shipping = addShippingInfo(storedShippingData.shippingInfo);
    } else {
      cart = addShippingInfo([]);
    }
  }, [cart]);

  //Return
  return (
    <CartContext.Provider
      value={{
        cartProducts,
        shippingInfo,
        addProduct,
        addShippingInfo,
        increaseQuantity,
        descreaseQuantity,
        clearCart,
        removeCartItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
