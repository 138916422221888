import { Button } from "@chakra-ui/react";
import styled from "styled-components";

export const GradientButton = styled.button`
  width: ${({ width }) => (width ? width : "137px")};
  height: 45px;
  color: #fff;
  /* UI Properties */
  background: transparent linear-gradient(63deg, #f54748 0%, #ecc686 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  border: 1px solid #ecc686;
  font-weight: bold;
  &:hover {
    background: #e9e4f2;
    color: #f36556;
  }
  &:disabled {
    background: #e9e4f2;
    color: #f36556;
    cursor: not-allowed;
  }

  ${
    "" /* @media screen and (max-width: 756px) {
    width: ${({ width }) => (width ? "40%" : "137px")};
  } */
  }
  ${"" /* width: ${({ isDisabled }) => (isDisabled ? width : "137px")}; */}
`;

export const PageBg = styled.div`
  background: transparent linear-gradient(13deg, #ffffff 0%, #f7f2ee 100%) 0% 0%
    no-repeat padding-box;
  font-family: "Poppins";
`;
