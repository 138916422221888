import {
  Button,
  Input,
  Box,
  Center,
  Divider,
  FormLabel,
  FormControl,
  Text,
  WrapItem,
  Wrap,
  useToast,
  Stack,
  InputGroup,
  Textarea,
  InputRightAddon,
  Img,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  HStack,
  InputLeftAddon,
  Checkbox,
  Heading,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../utilities/hooks/auth-hook";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { GridLoader, PuffLoader, RingLoader } from "react-spinners";
import AvatarUpload from "../utilities/components/AvatarUpload";
import ImageUpload from "../utilities/components/ImageUpload";

const ProfileChangeModal = ({ isOpen, onClose }) => {
  const { user, token, logout } = useAuth();
  const toast = useToast();
  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(
    "https://res.cloudinary.com/wow-importer/image/upload/v1657821067/demo-user_xgcsjx.png"
  );

  console.log({ avatarPreview });
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  console.log({ avatarPreview });

  const myBasicAccount = useQuery(
    [`myBasicAccount`, token],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}me`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (data) => {
        console.log({ data });
        data?.data?.user?.avatar?.url &&
          setAvatarPreview(data?.data?.user?.avatar?.url);
      },
    }
  );

  const updateProfile = useMutation(
    async (updateProfile) =>
      await axios.put(
        process.env.REACT_APP_BACKEND_URL + `me/update`,
        updateProfile?.formData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {
    name: myBasicAccount?.data?.data?.user?.name,
    email: myBasicAccount?.data?.data?.user?.email,
    image: myBasicAccount?.data?.data?.user?.avatar?.url,
    phone: myBasicAccount?.data?.data?.user?.phoneNumber,
    imageTouched: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    image: Yup.mixed(),
    phone: Yup.string()
      .min(9, "Too Short!")
      .max(12, "Too Long!")
      .required("Required"),
  });
  let formData = new FormData();

  const onSubmit = async (values) => {
    const { name, email, image, phone } = values;

    formData.append("name", name);
    formData.append("email", email);
    formData.append("avatar", avatar);
    formData.append("phoneNumber", phone);

    try {
      updateProfile.mutate(
        {
          formData,
        },
        {
          onSuccess: (responseData) => {
            onClose();
            myBasicAccount?.refetch();
            toast({
              title: "Profile updated successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          },
          onError: (responseDataError) => {
            console.log({ responseDataError });
            toast({
              title:
                responseDataError?.response?.data?.message ===
                "Could not decode base64"
                  ? "Image not Compatible. Choose a different picture"
                  : (responseDataError?.response?.data?.errors?.email &&
                      responseDataError?.response?.data?.errors?.email[0]) ||
                    (responseDataError?.response?.data?.errors?.phone &&
                      responseDataError?.response?.data?.errors?.phone[0]) ||
                    responseDataError?.response?.data?.message ||
                    "Profile updated not updated.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        }
      );
      // Redirect the admin to a different page
    } catch (err) {}
  };

  const CustomInput = ({
    extra,
    field,
    title,
    htmlFor,
    type,
    error,
    touched,
  }) => {
    return (
      <Box w={{ base: "100%", md: "70%" }}>
        <FormControl isInvalid={error && touched}>
          <Stack spacing="3px">
            {extra === "phone" ? (
              <Input
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 9);
                }}
                style={{
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
                fontSize={{
                  base: "18px",
                  sm: "14px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                  "2xl": "18px",
                }}
                {...field}
                type="number"
                className="form-control"
                placeholder="Phone Number"
              />
            ) : (
              <Input
                w={{ base: "280px", lg: "320px" }}
                {...field}
                id={htmlFor}
                placeholder={title}
                type={type}
              />
            )}
            {error && touched && (
              <Text noOfLines={1} color="red">
                {error}
              </Text>
            )}
          </Stack>
        </FormControl>
      </Box>
    );
  };
  console.log({ updateProfile });

  const onChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
        setAvatar(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        {!myBasicAccount?.isLoading ? (
          <Center py={5}>
            {myBasicAccount?.isSuccess ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(values) => {
                  console.log({ values });
                  return (
                    <Form>
                      <Box my={4}>
                        <Center
                          color="#AFAFAF"
                          fontSize="25px"
                          fontWeight="semibold"
                        >
                          Edit Profile
                        </Center>
                        <Divider color="#333" my={4} />
                      </Box>
                      <Stack fontSize="18px" justify="center" spacing={4}>
                        <Stack justify="center" spacing={4}>
                          <Field name="name">
                            {({ field, form }) => (
                              <CustomInput
                                field={field}
                                form={form}
                                title="Name"
                                htmlFor="name"
                                error={values.errors.name}
                                touched={values.touched.name}
                              />
                            )}
                          </Field>
                          <Field name="email">
                            {({ field, form }) => (
                              <CustomInput
                                field={field}
                                form={form}
                                title={"Email"}
                                htmlFor="email"
                                error={values.errors.email}
                                touched={values.touched.email}
                              />
                            )}
                          </Field>{" "}
                          <Field name="phone">
                            {({ field, form }) => (
                              <CustomInput
                                field={field}
                                form={form}
                                title="Phme mumber"
                                htmlFor="phone"
                                error={values.errors.phone}
                                touched={values.touched.phone}
                                extra="phone"
                              />
                            )}
                          </Field>
                          <Field name="image">
                            {({ field, form }) => (
                              <Stack
                                px={{ base: "10%", lg: "0%" }}
                                className="form-group"
                              >
                                <label htmlFor="catIcon_upload">Avatar</label>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <figure className="catIcon mr-3 item-rtl">
                                      <Img
                                        maxH="150px"
                                        src={avatarPreview}
                                        className="rounded-circle"
                                      />
                                    </figure>
                                  </div>
                                  <div className="custom-file">
                                    <input
                                      type="file"
                                      name="catIcon"
                                      className="custom-file-input"
                                      id="customFile"
                                      accept="image/png, image/jpeg"
                                      onChange={(e) => {
                                        values?.setFieldValue(
                                          "imageTouched",
                                          true
                                        );
                                        onChange(e);
                                      }}
                                      placeholder="Upload Avatar"
                                    />
                                  </div>
                                </div>
                              </Stack>
                            )}
                          </Field>
                        </Stack>
                        <Center>
                          <Button
                            my={5}
                            bg={"#f54748"}
                            color={"white"}
                            _hover={{
                              bg: "#FFF",
                              color: "#f54748",
                              border: "1px solid #f54748",
                            }}
                            disabled={
                              myBasicAccount?.data?.data?.user?.avatar?.url !==
                                avatarPreview &&
                              !myBasicAccount?.data?.data?.user?.avatar?.url &&
                              avatarPreview !=
                                "https://res.cloudinary.com/wow-importer/image/upload/v1657821067/demo-user_xgcsjx.png"
                                ? !values.isValid && !updateProfile?.isLoading
                                : !(
                                    values.isValid &&
                                    values.dirty &&
                                    !updateProfile?.isLoading
                                  )
                            }
                            type={
                              !updateProfile?.isLoading ? "submit" : "button"
                            }
                            isLoading={updateProfile?.isLoading}
                          >
                            Edit Profile
                          </Button>
                        </Center>
                      </Stack>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Center>Check your connection and refresh </Center>
            )}
          </Center>
        ) : (
          <Center alignSelf={"center"} minH="500px">
            <GridLoader size={12} color="#EEA878" />
          </Center>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ProfileChangeModal;
