import { Spinner } from "@chakra-ui/spinner";
import React from "react";
import { SpinnerWrapper } from "./Spinner.elements";

const ChakraSpinner = (props) => {
  return (
    <SpinnerWrapper>
      <Spinner size='xl'></Spinner>
    </SpinnerWrapper>
  );
};

export default ChakraSpinner;
