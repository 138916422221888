import React, { useState } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
  Center,
  Button,
} from "@chakra-ui/react";
import { FiMenu, FiChevronDown } from "react-icons/fi";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/auth-hook";
import { useQuery } from "react-query";
import axios from "axios";
import { Logo } from "../../Logo";
import { Nav, NavLogo } from "./AdminNav.elements";
import PasswordChangeModal from "../../../../auth/PasswordChangeModal";
import ProfileChangeModal from "../../../../auth/ProfileChangeModal";
import { useLang } from "../../../hooks/lang-hook";
import { adminRoutes } from "../../../../routes/adminRoutesData";
import AdminBreadCrumb from "../../breadCrumbs/AdminBreadCrumb";

export default function AdminNavigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [passChange, setPassChange] = useState(false);
  const [profile, setProfile] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const { logout, token } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const myBasicAccount = useQuery(
    [`myBasicAccount`, token],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}me`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onError: (res) => {
        if (token && res?.response?.status === 403) {
          logout();
          navigate("/login");
          toast({
            title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (res?.message === "Network Error") {
          toast.closeAll();
          toast({
            title: (
              <HStack>
                <Text>Check your internet connection and </Text>
                <Button color={"#000"} onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </HStack>
            ),
            description: "Seems like there is issue with your connection",
            status: "error",
            duration: null,
          });
        }
      },
    }
  );
  const onProfileExit = () => {
    setPassChange(false);
    setProfile(false);
    onClose();
  };

  console.log({ myBasicAccount });

  return (
    <Box minH="100vh" bg={"#f3fff2"}>
      <PasswordChangeModal isOpen={passChange} onClose={onProfileExit} />
      <ProfileChangeModal isOpen={profile} onClose={onProfileExit} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", lg: "block" }}
        myBasicAccount={myBasicAccount}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
      >
        <DrawerContent>
          {!myBasicAccount?.isLoading && myBasicAccount?.isSuccess && (
            <SidebarContent onClose={onClose} />
          )}
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav
        myBasicAccount={myBasicAccount}
        setPassChange={setPassChange}
        setProfile={setProfile}
        onOpen={onOpen}
      />
      <Box ml={{ base: 0, lg: 60 }} p="4">
        <Outlet />
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", lg: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        onClick={onClose}
        h="20"
        alignItems="center"
        pr="10px"
        justifyContent="center"
      >
        <NavLogo to="/dashboard">
          <Center>
            <Logo />
          </Center>
        </NavLogo>
        <CloseButton display={{ base: "flex", lg: "none" }} onClick={onClose} />
      </Flex>
      {adminRoutes
        .filter((route) => route.sideBarVisible)
        .map((link, index) => (
          <NavItem
            to={link?.path}
            key={index}
            onClose={onClose}
            icon={link.icon}
          >
            {link?.name}
          </NavItem>
        ))}
    </Box>
  );
};

const NavItem = ({ icon, children, onClose, to, ...rest }) => {
  const location = useLocation();
  console.log("location", location);
  console.log("to", to);

  return (
    <Link to={to} _focus={{ boxShadow: "none" }}>
      <Flex
        onClick={onClose}
        style={{
          background:
            location?.pathname.toLowerCase().startsWith(to.toLowerCase()) ||
            location?.pathname.toLowerCase() === `${to.toLowerCase()}/`
              ? "#F55A5A"
              : "initial",
          color:
            location?.pathname.toLowerCase().startsWith(to.toLowerCase()) ||
            location?.pathname.toLowerCase() === `${to.toLowerCase()}/`
              ? "#FFF"
              : "initial",
        }}
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        bg={"#FFF"}
        cursor="pointer"
        {...rest}
      >
        <HStack>
          {icon}
          <Text>{children}</Text>
        </HStack>
      </Flex>
    </Link>
  );
};

const MobileNav = ({
  onOpen,
  setPassChange,
  setProfile,
  count,
  myBasicAccount,
  ...rest
}) => {
  const [logoutSwitch, setLogoutSwitch] = useState(false);
  const { changeLang, isOro, isAmh } = useLang();

  const navigate = useNavigate();
  const { user, logout, token } = useAuth();
  const toast = useToast();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  console.log({ myBasicAccount });

  const logOutQuery = useQuery(
    "logOutQuery",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}logout`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token && logoutSwitch,
    }
  );

  const onLogout = () => {
    setLogoutSwitch(true);
    logout();
  };

  return (
    <Nav>
      <Flex
        ml={{ base: 0, lg: 60 }}
        px={{ base: 4, lg: 4 }}
        height="70px"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between", lg: "flex-end" }}
        {...rest}
        overflow=" hidden"
        background-color=" #333"
        zIndex={800}
      >
        <Box flexGrow={1}>
          <AdminBreadCrumb />
        </Box>
        <IconButton
          display={{ base: "flex", lg: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />
        <HStack spacing={{ base: "0", lg: "6" }}>
          {" "}
          {/* <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            icon={<FiBell />}
          />{" "} */}
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <HStack>
                  <Avatar
                    size={"sm"}
                    src={`${myBasicAccount?.data?.data?.user?.avatar?.url}`}
                  />
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm" maxW="100px" isTruncated>
                      {myBasicAccount?.data?.data?.data?.name}
                    </Text>
                    <Text fontSize="xs" color="gray.600">
                      {myBasicAccount?.data?.data?.data?.role?.name}
                    </Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
              // bg={useColorModeValue("white", "gray.900")}
              // borderColor={useColorModeValue("gray.200", "gray.700")}
              >
                <Link to="/">
                  <MenuItem display={token ? "flex" : "none"}>
                    Back to User Mode
                  </MenuItem>
                </Link>
                <MenuItem
                  onClick={() => {
                    setProfile(true);
                  }}
                >
                  Profile
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setPassChange(true);
                  }}
                >
                  Change Password
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    onLogout();
                  }}
                >
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    </Nav>
  );
};
