import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  //Hook

  // declare usefull auth states

  const [type, setType] = useState(null);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [checked, setChecked] = useState(false);

  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const userData = useQuery(
    `userData${token}`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}me`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (responseData) => {
        setUser(responseData?.data?.data);
        localStorage.setItem(
          "lidyaSpiceAdminAuthData",
          JSON.stringify({
            token,
            type,
            user: responseData?.data?.data,
          })
        );
      },
    }
  );

  //Function
  const login = useCallback((token, type, user) => {
    console.log({ token, type, user });
    setType(type);
    setToken(token);
    setUser(user);
    localStorage.setItem(
      "lidyaSpiceAdminAuthData",
      JSON.stringify({
        token,
        type,
        user,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setType(null);
    setToken(null);
    localStorage.setItem(
      "lidyaSpiceAdminAuthData",
      JSON.stringify({
        type: null,
        token: null,
        user: null,
      })
    );
  }, []);

  let auth;

  useEffect(() => {
    const storedData = JSON.parse(
      localStorage.getItem("lidyaSpiceAdminAuthData")
    );
    if (storedData) {
      if (storedData.token) {
        auth = login(storedData.token, storedData.type, storedData.user);
      }
    } else {
      auth = login(null, null, null);
    }
    setChecked(true);
  }, [auth]);

  //Return
  return (
    <AuthContext.Provider value={{ token, type, user, checked, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
