import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

const LangContext = createContext();

export function useLang() {
  return useContext(LangContext);
}

export function LangProvider({ children }) {
  const [isAmh, setIsAmh] = useState(false);
  const [isOro, setIsOro] = useState(false);
  const changeLang = useCallback((isAmh, isOro) => {
    setIsAmh(isAmh);
    setIsOro(isOro);

    localStorage.setItem(
      "langData",
      JSON.stringify({
        isAmh,
        isOro,
      })
    );
  }, []);

  let lang;
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("langData"));
    if (storedData) {
      if (storedData?.isAmh) {
        lang = changeLang(true, false);
      } else if (storedData?.isOro) {
        lang = changeLang(false, true);
      }
    }
  }, [lang]);

  //Return
  return (
    <LangContext.Provider value={{ isAmh, isOro, changeLang }}>
      {children}
    </LangContext.Provider>
  );
}
