import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../../Logo";
import { navLinks } from "../Data";

const NavigationDrawer = ({ isOpen, onClose }) => {
  return (
    <Drawer isOpen={isOpen} placement="left" size={"xs"} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Logo />
        </DrawerHeader>

        <DrawerBody>
          <Stack
            pt="10px"
            spacing="20px"
            direction={{ base: "column", lg: "row" }}
          >
            {navLinks?.map((link, index) => {
              return (
                <Link
                  key={index}
                  to={link?.path}
                  onClick={onClose}
                  _hover={{ color: "white" }}
                >
                  <Text fontWeight={"bold"} fontSize="17px">
                    {link?.name}
                  </Text>
                </Link>
              );
            })}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NavigationDrawer;
