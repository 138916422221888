import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./utilities/hooks/auth-hook";
import { LangProvider } from "./utilities/hooks/lang-hook";
import { CartProvider } from "./utilities/hooks/cart-hook";

const queryClient = new QueryClient();
console.log({ queryClient });
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <LangProvider>
      <AuthProvider>
        <CartProvider>
          <ChakraProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ChakraProvider>
          <ReactQueryDevtools />
        </CartProvider>
      </AuthProvider>
    </LangProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
