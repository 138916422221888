import { Box, Img } from "@chakra-ui/react";
import React from "react";

export const Logo = ({ color }) => {
  console.log({ color });
  return (
    <Box>
      <Img
        maxH={{ base: "42px", sm: "60px" }}
        minH={{ base: "42px", md: "60px" }}
        src={
          "https://res.cloudinary.com/wow-importer/image/upload/v1665821975/lidya_spice_logo_zvikai.svg"
        }
      />
    </Box>
  );
};
