import {
  Button,
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  HStack,
  Center,
  useToast,
  Text,
  Box,
  Wrap,
  VStack,
  Img,
  Spinner,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import { useLang } from "../../utilities/hooks/lang-hook";
import CustomInput from "../../utilities/components/CustomInput";
import { Logo } from "../../utilities/components/Logo";
import { useState } from "react";
import { GradientButton } from "../../utilities/components/Shared.elements";
import { useAuth } from "../../utilities/hooks/auth-hook";

export default function RegisterModal({ onRegister }) {
  const { isAmh } = useLang();
  const { login } = useAuth();

  const toast = useToast();
  const navigate = useNavigate();

  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(
    "https://res.cloudinary.com/wow-importer/image/upload/v1657821067/demo-user_xgcsjx.png"
  );

  console.log({ isAmh });

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const roles = [
    { _id: "1", name: "customer" },
    { _id: "2", name: "vendor" },
  ];
  const addUser = useMutation(
    async (addUser) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + `register`,
        addUser?.formData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {
    fullName: "",
    // userName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(3, isAmh ? "በጣም አጭር!" : "Too Short!")
      .max(35, isAmh ? "በጣም ረጅም!" : "Too Long!")
      .required(isAmh ? "ያስፈልጋል" : "Required"),
    // userName: Yup.string()
    //   .min(3, isAmh ? "በጣም አጭር!" : "Too Short!")
    //   .max(20, isAmh ? "በጣም ረጅም!" : "Too Long!")
    //   .required(isAmh ? "ያስፈልጋል" : "Required"),
    email: Yup.string().email("Invalid email format").required("Required"),

    phoneNumber: Yup.string()
      .min(9, isAmh ? "Too Short!" : "Too Short!")
      .max(35, isAmh ? "በጣም ረጅም!" : "Too Long!")
      .required(isAmh ? "ያስፈልጋል" : "Required"),
    password: Yup.string()
      .min(8, isAmh ? "በጣም አጭር!" : "Too Short!")
      .max(35, isAmh ? "በጣም ረጅም!" : "Too Long!")
      .required(isAmh ? "ያስፈልጋል" : "Required"),
    confirmPassword: Yup.string()
      .required(
        isAmh
          ? "የይለፍ ቃል አስፈላጊ መስክ መሆኑን ያረጋግጡ"
          : "Confirm password is a required field"
      )
      .label("Confirm password")
      .test(
        "passwords-match",
        isAmh ? "የሚስጥሩ ቃል ​​የግድ መመሳሰል አለበት" : "Passwords must match",
        function (value) {
          return this.parent.password === value;
        }
      ),
  });

  const onSubmit = async (values) => {
    const { fullName, password, email, phoneNumber } = values;

    console.log({ values });
    const formData = new FormData();

    formData.append("name", fullName);
    formData.append("email", email);
    formData.append("avatar", avatar);
    formData.append("phoneNumber", phoneNumber);
    formData.append("password", password);

    try {
      addUser.mutate(
        {
          formData,
        },
        {
          onSuccess: (responseData) => {
            if (responseData?.data?.token) {
              login(
                responseData?.data?.token,
                responseData?.data?.user?.role,
                responseData?.data?.user
              );
              navigate("/");

              toast({
                title: "User created successfully",
                description: responseData?.data?.message,
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            } else {
              onRegister(false);

              toast({
                title: "Login failed.",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            // navigate("/otp/+18623051419");
          },
          onError: (responseDataError) => {
            console.log({ responseDataError });
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                "User not created",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
          },
        }
      );
      // Redirect the admin to a different page
    } catch (err) {}
  };

  const onChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
        setAvatar(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      spacing={{ base: "10px", md: "50px" }}
      align={"center"}
      justify={"center"}
    >
      <Flex>
        <Stack
          direction={{ base: "column" }}
          rounded={"xl"}
          p={{ base: "0px 5px", lg: "40px 50px" }}
          my={{ base: 8, md: 2 }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(values) => {
              console.log({ values });
              return (
                <Box>
                  <Form>
                    <Stack p={"0px"} spacing={5} w={"full"} maxW={"4xl"}>
                      <VStack spacing={"22px"}>
                        {/* <Logo /> */}
                        <Center pb="20px">
                          <VStack spacing={"22px"}>
                            <Logo />
                          </VStack>
                        </Center>
                        <Heading
                          lineHeight={1.1}
                          fontSize={{ base: "2xl", sm: "3xl" }}
                          align="center"
                        >
                          Create account
                        </Heading>
                      </VStack>

                      <Wrap
                        pl={{ base: "0%", md: "3%", lg: "10%" }}
                        spacing={{
                          base: "20px",
                          md: "20px",
                          lg: "25px",
                          xl: "30px",
                        }}
                        my={2}
                      >
                        {" "}
                        <Field name="fullName">
                          {({ field, form }) => (
                            <CustomInput
                              field={field}
                              form={form}
                              title={isAmh ? "የመጀመሪያ ስም" : "Full Name"}
                              htmlFor="fullName"
                              error={values.errors.fullName}
                              touched={values.touched.fullName}
                            />
                          )}
                        </Field>
                        <Field name="phoneNumber">
                          {({ field, form }) => (
                            <CustomInput
                              type="number"
                              field={field}
                              form={form}
                              title={isAmh ? "ስልክ ቁጥር" : "Phone number"}
                              htmlFor="phoneNumber"
                              error={values.errors.phoneNumber}
                              touched={values.touched.phoneNumber}
                            />
                          )}
                        </Field>{" "}
                        <Field name="email">
                          {({ field, form }) => (
                            <CustomInput
                              field={field}
                              form={form}
                              title={"Email"}
                              htmlFor="email"
                              error={values.errors.email}
                              touched={values.touched.email}
                            />
                          )}
                        </Field>
                      </Wrap>
                      <Wrap
                        pl={{ base: "0%", md: "5%", lg: "10%" }}
                        spacing={{ base: "20px", md: "20px" }}
                        my={2}
                      >
                        <Field name="password">
                          {({ field, form }) => (
                            <CustomInput
                              field={field}
                              form={form}
                              type="password"
                              title={isAmh ? "የይለፍ ቃል" : "Password"}
                              htmlFor="password"
                              extra="password"
                              error={values.errors.password}
                              touched={values.touched.password}
                            />
                          )}
                        </Field>
                        <Field name="confirmPassword">
                          {({ field, form }) => (
                            <CustomInput
                              field={field}
                              form={form}
                              title={
                                isAmh ? "የይለፍ ቃል አረጋግጥ" : "Confirm Password"
                              }
                              htmlFor="confirmPassword"
                              extra="password"
                              error={values.errors.confirmPassword}
                              touched={values.touched.confirmPassword}
                              type="password"
                            />
                          )}
                        </Field>{" "}
                        <div className="form-group">
                          <label htmlFor="avatar_upload">Avatar</label>
                          <div className="d-flex align-items-center">
                            <div>
                              <figure className="avatar mr-3 item-rtl">
                                <Img
                                  maxH="150px"
                                  src={avatarPreview}
                                  className="rounded-circle"
                                  alt="Avatar Preview"
                                />
                              </figure>
                            </div>
                            <div className="custom-file">
                              <input
                                type="file"
                                name="avatar"
                                className="custom-file-input"
                                id="customFile"
                                accept="image/png, image/jpeg"
                                onChange={onChange}
                              />
                              {/* <label
                                className="custom-file-label"
                                htmlFor="customFile"
                              >
                                Choose Avatar
                              </label> */}
                            </div>
                          </div>
                        </div>
                      </Wrap>
                      <VStack pt="10px" spacing={"15px"}>
                        <GradientButton
                          width="250px"
                          disabled={
                            !(
                              values.isValid &&
                              values.dirty &&
                              !addUser.isLoading
                            )
                          }
                          type={!addUser?.isLoading ? "submit" : "button"}
                          color={"white"}
                          _hover={{
                            bg: "#FFF",
                            color: "#F55A5A",
                          }}
                          py={6}
                          border={"1px solid #F55A5A"}
                        >
                          {addUser?.isLoading ? (
                            <Center>
                              <Spinner />
                            </Center>
                          ) : (
                            "Create an account"
                          )}
                        </GradientButton>
                        <Text fontSize={"12px"} maxW="70%">
                          By creating an account, you agree to Lomi's Conditions
                          of Use and Privacy Notice.{" "}
                        </Text>{" "}
                        <HStack fontSize={"18px"}>
                          <Center>Already have an account?</Center>
                          <Link to="#" onClick={() => onRegister(false)}>
                            <Center color="#f16162">Sign in</Center>
                          </Link>
                          <Text>.</Text>
                        </HStack>
                      </VStack>
                    </Stack>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Stack>
      </Flex>
    </Stack>
  );
}
