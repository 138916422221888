import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
  WrapItem,
  InputLeftAddon,
  InputGroup,
  Text,
  Box,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useLang } from "../../../utilities/hooks/lang-hook";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import Select from "react-select";
import { useCart } from "../../../utilities/hooks/cart-hook";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import ChakraSpinner from "../../../utilities/components/ChakraSpinner";

const inputStyle = {
  iconColor: "#c4f0ff",
  color: "#000",
  fontWeight: "500",
  width: "300px",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "18px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#fce883",
  },
  "::placeholder": {
    color: "grey",
  },
};

const CreditCardForm = () => {
  const { isAmh } = useLang();
  const { cartProducts, clearCart } = useCart();
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { token, user } = useAuth();
  const { addShippingInfo, shippingInfo } = useCart();
  const toast = useToast();
  const navigate = useNavigate();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const paymentMutation = useMutation(
    async (newLogin) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "payment/process",
        newLogin,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  console.log({ paymentMutation });

  const orderMutation = useMutation(
    async (newLogin) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "order/new",
        newLogin,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const myBasicAccount = useQuery(
    [`myBasicAccount`, token],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}me`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );

  const initialValues = {
    cardExpiry: "",
    cardNumber: "",
    cardCvc: "",
  };

  const validationSchema = Yup.object().shape({
    cardExpiry: Yup.string().required("Required"),
    cardNumber: Yup.string().required("Required"),
    cardCvc: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const { postalCode, firstName, phoneNumber, address } = values;

    // const { firstName, phoneNumber, password, confirmPassword } = values;
    // "name": "Brook Yonas",
    // "username": "brook",
    // "email": "brookyonas7@gmail.com",
    // "phonenumber": "0911658569",
    console.log({ values });
    // formData.append("name", name);
    // formData.append("address", address);
    // formData.append("description", description);
    // formData.append("category_id", category?._id);
    // formData.append("unit_id", unit?._id);
    // formData.append("image", image);

    try {
      addShippingInfo({
        postalCode,
        address,
        phoneNo: phoneNumber,
        city: "AA",
        state: "AA",
        country: "Ethiopia",
      });
      navigate("/cart/shipping/payment");
      // Redirect the admin to a different page
    } catch (err) {}
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let res;
    try {
      paymentMutation.mutate(
        {
          amount:
            (
              cartProducts?.reduce(
                (acc, product) => acc + product?.price * product?.quantity,
                0
              ) *
                1.15 +
              50
            )?.toFixed() * 100,
        },
        {
          onSuccess: async (data) => {
            setPaymentProcessing(true);

            console.log(data);

            const clientSecret = data?.data.client_secret;
            console.log({ clientSecret });
            console.log({ elements });
            if (!stripe || !elements) {
              return;
            }

            const result = await stripe.confirmCardPayment(clientSecret, {
              payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                  name: myBasicAccount?.data?.data?.user.name,
                  email: myBasicAccount?.data?.data?.user.email,
                },
              },
            });

            const order = {
              orderItems: cartProducts,
              shippingInfo,
              paymentType: "Credit Card",
            };
            // The payment is processed or not
            if (result?.paymentIntent?.status === "succeeded") {
              // TODO: New Order
              setPaymentProcessing(false);

              order.paymentInfo = {
                id: result.paymentIntent.id,
                status: result.paymentIntent.status,
              };
              orderMutation.mutate(
                {
                  order,
                },
                {
                  onSuccess: (data) => {
                    toast.closeAll();
                    toast({
                      title: "Ordered and Payed Successfully",
                      description: "You can now check your order",
                      status: "success",
                      duration: 15000,
                      isClosable: true,
                    });
                    navigate("/orders");
                    clearCart();
                  },
                  onError: (err) => {
                    console.log(err);
                    toast.closeAll();

                    toast({
                      title: "Payment Failed",
                      description: "Please try again",
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  },
                }
              );
            } else {
              setPaymentProcessing(false);

              console.log("first", result);
              toast({
                title: result?.error?.message ?? "Payment Failed",
                description: "Please try again with correct card details",
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
          },
          onError: async (err) => {
            console.log({ err });
            toast({
              title: err?.error?.message ?? "Payment Failed",
              description: "Please try again",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          },
        }
      );
    } catch (error) {
      console.log({ error });

      toast({
        title: "Payment Failed",
        description: "Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const CustomInput = ({
    field,
    title,
    htmlFor,
    type,
    error,
    touched,
    extra,
    form,
    isMulti,
    myDefault,
    options,
  }) => {
    return (
      <WrapItem
        my={4}
        w={
          htmlFor === "address"
            ? { base: "100%", md: "100%" }
            : { base: "100%", md: "45%" }
        }
        // w={ (isEmail || extra === "phone") ? "17.7rem" : extra === "password" ? "16.4rem" : "15.188rem" }
      >
        <FormControl isInvalid={error && touched}>
          {/* <FormLabel htmlFor={htmlFor}>{title}</FormLabel> */}
          {extra === "phone" && (
            <InputGroup>
              <InputLeftAddon
                fontSize={{
                  base: "18px",
                  sm: "14px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                  "2xl": "18px",
                }}
                children="+251"
                px={3}
              />
              <Input
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 9);
                }}
                style={{
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
                fontSize={{
                  base: "18px",
                  sm: "14px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                  "2xl": "18px",
                }}
                {...field}
                type="number"
                className="form-control"
                placeholder="Phone Number"
              />
            </InputGroup>
          )}
          {!extra && (
            <>
              <FormControl id="firstName" isRequired>
                {/* <FormLabel>Full name</FormLabel> */}
                <Input
                  placeholder={title}
                  _placeholder={{ color: "gray.500" }}
                  type={type ? type : "text"}
                  {...field}
                />
              </FormControl>
            </>
          )}
          {error && touched && <Text color="red">{error}</Text>}
        </FormControl>
      </WrapItem>
    );
  };
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      bg={useColorModeValue("gray.50", "gray.800")}
      spacing={{ base: "10px", md: "50px" }}
      overflow="hidden"
    >
      {(paymentMutation?.isLoading ||
        orderMutation?.isLoading ||
        paymentProcessing) && <ChakraSpinner asOverlay />}
      <Flex>
        <Stack
          direction={{ base: "column" }}
          rounded={"xl"}
          m={0}
          p={{ base: "20px", sm: "20px 50px" }}
          my={0}
        >
          <Box>
            <Stack p={"0px 20px"} spacing={5} w={"full"}>
              <Heading
                lineHeight={1.1}
                fontSize={{ base: "22px", sm: "24px", md: "26px" }}
              >
                Card Information
              </Heading>

              <div className="row wrapper">
                <div className="col-sm-12 col-md-10 col-lg-5">
                  <form className="shadow-lg" onSubmit={submitHandler}>
                    <Stack spacing={"10px"}>
                      <Stack spacing={"-3px"}>
                        <FormLabel htmlFor={"card_number_field"}>
                          <Text>Card Number </Text>
                        </FormLabel>{" "}
                        <Box
                          border="2px solid #CED4DA"
                          p={"10px 4px"}
                          borderRadius={"8px"}
                          w="280px"
                        >
                          <CardNumberElement
                            type="text"
                            id="card_num_field"
                            className="form-control"
                            options={{
                              style: {
                                base: inputStyle,
                              },
                            }}
                          />
                        </Box>
                      </Stack>

                      <Stack spacing={"-3px"}>
                        <FormLabel htmlFor={"card_expiry_field"}>
                          <Text>Card Expiry </Text>
                        </FormLabel>
                        <Box
                          border="2px solid #CED4DA"
                          p={"10px 4px"}
                          borderRadius={"8px"}
                          w="280px"
                        >
                          {" "}
                          <CardExpiryElement
                            type="text"
                            id="card_cvc_field"
                            className="form-control"
                            options={{
                              style: {
                                base: inputStyle,
                              },
                            }}
                          />
                        </Box>
                      </Stack>

                      <Stack spacing={"-3px"}>
                        <FormLabel htmlFor={"card_cvc_field"}>
                          <Text>Card CVC </Text>
                        </FormLabel>{" "}
                        <Box
                          border="2px solid #CED4DA"
                          p={"10px 4px"}
                          borderRadius={"8px"}
                          w="100px"
                        >
                          {" "}
                          <CardCvcElement
                            type="text"
                            id="card_exp_field"
                            className="form-control"
                            options={{
                              style: {
                                base: inputStyle,
                              },
                            }}
                          />
                        </Box>
                      </Stack>
                    </Stack>
                    <Button
                      mt="15px"
                      bg="#F4625E"
                      color="#fff"
                      border="1px solid #F4625E"
                      _hover={{ bg: "#FFF", color: "#F4625E" }}
                      id="pay_btn"
                      type="submit"
                      className="btn btn-block py-3"
                      disabled={
                        paymentMutation?.isLoading ||
                        orderMutation?.isLoading ||
                        paymentProcessing
                        // ||
                        // paymentMutation?.isSuccess
                      }
                    >
                      Pay
                      {` - $ ${(
                        cartProducts?.reduce(
                          (acc, product) =>
                            acc + product?.price * product?.quantity,
                          0
                        ) *
                          1.15 +
                        5
                      )?.toFixed(2)}`}
                    </Button>
                  </form>
                </div>
              </div>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default CreditCardForm;
