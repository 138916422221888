import { Box, Container, Stack, StackDivider } from "@chakra-ui/react";
import React from "react";
import CartBreadCrumb from "../../shipping/components/CartBreadCrumb";
import OrderCart from "../../shipping/components/OrderCart";
import OrderShippingInfo from "../../shipping/components/OrderShippingInfo";
import { PageBg } from "../../utilities/components/Shared.elements";
import CreditCardForm from "./components/CreditCardForm";
// import PayemnetMethod from "./component/PayemnetMethod";

const CreditCardPage = () => {
  return (
    <PageBg>
      <Stack
        pl={{
          base: "0%",
          sm: "4%",
          md: "1%",
          lg: "4%",
          xl: "8%",
          "2xl": "10%",
        }}
        pr={{
          base: "0%",
          sm: "4%",
          md: "2%",
          xl: "8%",
          "2xl": "10%",
        }}
        py={2}
      >
        <CartBreadCrumb />
        <Stack
          direction={{ base: "column", md: "row" }}
          // divider={<StackDivider borderColor="gray.200" />}
          style={{ overflow: "hidden" }}
          my={2}
        >
          <Container
            w={{ base: "100%", md: "57%", lg: "63%", xl: "70%" }}
            h="100%"
            maxW={"7xl"}
            p="5px 5px"
            mb="30px"
          >
            <Stack align={{ base: "inherit", md: "inherit" }}>
              {/* <PayemnetMethod /> */}
              <CreditCardForm />
            </Stack>
          </Container>
          <Stack
            w={{ base: "100%", md: "43%", lg: "37%", xl: "30%" }}
            h="100%"
            align={{ base: "center", lg: "end" }}
            spacing={"20px"}
          >
            <OrderCart />
            <OrderShippingInfo />
          </Stack>
        </Stack>
      </Stack>
    </PageBg>
  );
};

export default CreditCardPage;
