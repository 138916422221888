import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";

export const socialMediaData = [
  {
    name: "Facebook",
    icon: <BsFacebook size={"1.6em"} color="#4C7CEE" />,
    link: "https://www.facebook.com/",
  },
  {
    name: "Twitter",
    icon: <BsTwitter size={"1.6em"} color="#2296E3" />,
    link: "https://www.twitter.com/",
  },
  {
    name: "Instagram",
    icon: <BsInstagram size={"1.6em"} color="#5851DB" />,
    link: "https://www.instagram.com/",
  },
];
