import "./App.css";
import ScrollToTop from "./utilities/functions/ScrollToTop";
import { Suspense, useEffect, useState } from "react";
import Footer from "./utilities/components/Footer";
import { GridLoader } from "react-spinners";
import { Center, Stack } from "@chakra-ui/react";
import { useAuth } from "./utilities/hooks/auth-hook";
import AdminRoutes from "./routes/AdminRoutes";
import CustomerRoutes from "./routes/CustomerRoutes";
import NoAuthRoutes from "./routes/NoAuthRoutes";
import MainNavigation from "./utilities/components/navigation/mainNavigation/MainNavigation";
import { useQuery } from "react-query";
import axios from "axios";

function App() {
  const { id, type, token, login, logout, checked } = useAuth();
  const [stripeAPiKey, setStripeApiKey] = useState("");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const stripeapi = useQuery(
    [`stripeapi`, token],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stripeapi`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );

  let routes;

  if (token && type === "admin") {
    routes = <AdminRoutes stripeapi={stripeapi} />;
  } else if (token && type === "user") {
    routes = <CustomerRoutes stripeapi={stripeapi} />;
  } else if (!stripeapi?.isLoading && checked) {
    routes = <NoAuthRoutes />;
  }

  return (
    <Stack>
      <ScrollToTop />

      <main>
        <Suspense
          fallback={
            <Center minH="90vh">
              <GridLoader size={14} color="#EEA878" />
            </Center>
          }
        >
          {" "}
          {checked && !stripeapi?.isLoading ? (
            routes
          ) : (
            <Center minH="90vh">
              <GridLoader size={14} color="#EEA878" />
            </Center>
          )}{" "}
        </Suspense>
      </main>
      <Footer />
    </Stack>
  );
}

export default App;
