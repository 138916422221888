import styled from "styled-components";
import { Box, Spinner } from "@chakra-ui/react";

export const StyledSpinner = styled(Spinner)`
  display: inline-block;
  width: 64px;
  height: 64px;
`;

export const SpinnerWrapper = styled(Box)`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

// const rotate = keyframes`
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//   }
// `

/* @keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
