export const navLinks = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Products",
    path: "/products-list/page=1",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Contact",
    path: "/contact",
  },
];
