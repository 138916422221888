import {
  Text,
  HStack,
  Stack,
  Box,
  Wrap,
  Img,
  Button,
  Center,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { socialMediaData } from "../Data";
import { Logo } from "./Logo";
import { navLinks } from "./navigation/mainNavigation/Data";

const Footer = () => {
  const location = useLocation();
  const { pathname } = location;

  const adminPages = pathname?.toLowerCase().startsWith("/dashboard");

  return (
    <Stack display={adminPages ? "none" : "block"} spacing={0} color="#FFF">
      <Stack py="3%" bg="#F4625E" justify={"center"} align="center">
        <Stack textAlign={"center"}>
          <Text fontSize={{ base: "4xl", sm: "5xl" }}>Contact Us</Text>
          <Text fontSize={{ base: "3xl", sm: "4xl" }}>
            560 Mission St, San Francisco
          </Text>
          <Text fontSize={{ base: "2xl", sm: "3xl" }}>CA 3434 USA</Text>
        </Stack>
        <HStack spacing={"15px"}>
          {socialMediaData?.map((item, index) => {
            return (
              <a key={index} target={"_blank"} href={item?.link}>
                <Box borderRadius={"15px"} p={"7px"} bg="#FFF">
                  {item.icon}
                </Box>
              </a>
            );
          })}
        </HStack>
      </Stack>
      <Wrap
        py="2%"
        bg="#393838"
        spacingX={"25px"}
        spacingY={"15px"}
        justify={{ base: "center", md: "space-between" }}
        align="center"
        px="5%"
      >
        <Box w={{ base: "initial", md: "20%" }}>
          <Logo color="#FFF" />
        </Box>
        <HStack justify={"center"} spacing="35px" align={"center"}>
          {navLinks?.map((link, index) => {
            return (
              <Link key={index} to={link?.path} _hover={{ color: "white" }}>
                <Text fontWeight={"bold"} fontSize="17px">
                  {link?.name}
                </Text>
              </Link>
            );
          })}
        </HStack>
        <HStack w={{ base: "initial", md: "20%" }}>
          <Box flexGrow="1" />
          <Button
            bg="#f54748"
            cursor={"pointer"}
            // onClick={onOpen}
            p={{ base: 0, sm: 1 }}
            opacity={0.9}
            _active={{ bg: "transparent" }}
            _hover={{ opacity: 1 }}
            display={{ base: "none", md: "flex" }}
            // _focus={{ bg: "transparent" }}
          >
            <AiOutlineShoppingCart color="" size={26} />
          </Button>
        </HStack>
      </Wrap>
      <VStack spacing={"3px"} bg="#F4625E" py="1.5%" textAlign={"center"}>
        <Center>
          ©️{1900 + new Date().getYear()} Copyright: Lidya Spices All rights
          reserved
        </Center>
        <Text>Developed by Technacy Systems</Text>
      </VStack>
    </Stack>
  );
};

export default Footer;
