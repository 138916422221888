import styled from "styled-components";

export const Nav = styled.nav`
  background: #f7f3ef;
`;

export const LoginGradientButton = styled.button`
  width: 98px;
  height: 45px;
  /* UI Properties */
  background: transparent linear-gradient(63deg, #f54748 0%, #ecc686 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  color: #fff;
  border: 1px solid #ecc686;
  font-weight: bold;
  &:hover {
    background: #e9e4f2;
    color: #f36556;
  }
  @media screen and (max-width: 756px) {
    width: 78px;
    height: 40px;
  }
  @media screen and (max-width: 480px) {
    width: 70px;
    height: 38px;
  }
`;
