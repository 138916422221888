import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Logo } from "../../Logo";
import NavigationDrawer from "./components/NavigationDrawer";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { navLinks } from "./Data";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import LoginModal from "../../../../auth/login/LoginModal";
import { useAuth } from "../../../hooks/auth-hook";
import { useQuery } from "react-query";
import axios from "axios";
import { Nav } from "../adminNavigation/AdminNav.elements";
import * as Yup from "yup";

import { FiChevronDown } from "react-icons/fi";
import ProfileChangeModal from "../../../../auth/ProfileChangeModal";
import PasswordChangeModal from "../../../../auth/PasswordChangeModal";
import { useCart } from "../../../hooks/cart-hook";
import { LoginGradientButton } from "./components/Navigation.elements";
import { PageBg } from "../../Shared.elements";

const MainNavigation = () => {
  const { token, logout, type } = useAuth();
  const { cartProducts } = useCart();
  const [isSmallerThan440] = useMediaQuery("(max-width: 440px)");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [openLogin, setOpenLogin] = useState(false);
  const [register, onRegister] = useState(false);
  const [profile, setProfile] = useState(false);
  const [passChange, setPassChange] = useState(false);
  const [logoutSwitch, setLogoutSwitch] = useState(false);
  const [redirectURL, setRedirectURL] = useState(false);
  const toast = useToast();
  const location = useLocation();
  const { pathname } = location;
  const adminPages = pathname?.toLowerCase().startsWith("/dashboard");

  const navigate = useNavigate();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const myBasicAccount = useQuery(
    [`myBasicAccount`, token],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}me`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        toast.closeAll();
      },
      onError: (res) => {
        if (token && res?.response?.status === 403) {
          logout();
          navigate("/login");
          toast({
            title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (res?.message === "Network Error") {
          toast.closeAll();
          toast({
            title: (
              <HStack>
                <Text>Check your internet connection and </Text>
                <Button color={"#000"} onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </HStack>
            ),
            description: "Seems like there is issue with your connection",
            status: "error",
            duration: null,
          });
        }
      },
    }
  );

  console.log({ myBasicAccount });
  const onProfileExit = () => {
    setPassChange(false);
    setProfile(false);
    onClose();
  };

  const logOutQuery = useQuery(
    "logOutQuery",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}logout`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token && logoutSwitch,
      onSuccess: (res) => {
        logout();
        setLogoutSwitch(false);
        toast({
          title: "Logged out successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (res) => {
        setLogoutSwitch(false);
        logout();

        // toast({
        //   title: "Not Logged out.",
        //   status: "error",
        //   duration: 3000,
        //   isClosable: true,
        // });
      },
    }
  );

  const onLogout = () => {
    setLogoutSwitch(true);
  };

  const initialValues = {
    searchQuery: "",
  };

  const validationSchema = Yup.object().shape({
    searchQuery: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    navigate(`/products/search/${values.searchQuery}`);
  };
  return (
    <PageBg>
      <Stack spacing={"0px"}>
        <Nav>
          <Flex
            zIndex={100}
            // position="sticky"
            // top="0"
            overflowY="visible"
            py="4px"
            direction={"row"}
            align="center"
            justify={{ base: "space-between", md: "space-between" }}
            w="100%"
            px={{ base: "3%", md: "0%", lg: "3%", xl: "4%", "2xl": "4.5%" }}
            bg="#F7F3EF"
            overflow={"hidden"}
            display={token && type === "admin" && adminPages ? "none" : "flex"}
          >
            {" "}
            <PasswordChangeModal isOpen={passChange} onClose={onProfileExit} />
            <ProfileChangeModal isOpen={profile} onClose={onProfileExit} />
            <LoginModal
              isOpen={openLogin}
              onClose={() => {
                setOpenLogin(false);
                onRegister(false);
              }}
              onRegister={onRegister}
              register={register}
              redirectURL={redirectURL}
            />
            <NavigationDrawer isOpen={isOpen} onClose={onClose} />
            <HStack
              w={{ base: "initial", md: "19%", lg: "19%" }}
              spacing={"15px"}
            >
              <Box
                cursor={"pointer"}
                onClick={onOpen}
                display={{ base: "flex", lg: "none" }}
                pl={{ base: "0px", md: "15%" }}
              >
                <GiHamburgerMenu size={22} />
              </Box>
              <Box>
                <Link to="/">
                  <Logo h={isSmallerThan440 && 24} />
                </Link>
              </Box>
            </HStack>
            <HStack
              justify={"center"}
              display={{ base: "none", lg: "flex" }}
              spacing="35px"
            >
              {navLinks?.map((link, index) => {
                return (
                  <Link
                    key={index}
                    to={link?.path}
                    onClick={onClose}
                    _hover={{ color: "white" }}
                  >
                    <Text fontWeight={"bold"} fontSize="17px">
                      {link?.name}
                    </Text>
                  </Link>
                );
              })}
            </HStack>
            <HStack
              justify={{ base: "end", md: "end", lg: "end" }}
              spacing={{ base: "6px", sm: "18px" }}
              w={{ base: "initial", md: "25%", lg: "20%" }}
            >
              <HStack spacing={{ base: "0px", sm: "10px" }}>
                {/* <Menu>
                <MenuButton>
                  <Button
                    bg="transparent"
                    cursor={"pointer"}
                    // onClick={onOpen}
                    p={{ base: 0, sm: 1 }}
                    _hover={{ bg: "#f2cdcd" }}
                    _active={{ bg: "transparent" }}
                  >
                    <AiOutlineSearch size={isSmallerThan440 ? 20 : 28} />
                  </Button>{" "}
                </MenuButton>
                <MenuList>
                  <MenuItem>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {(values) => {
                        console.log({ values });
                        return (
                          <Form>
                            <Field name="searchQuery">
                              {({ field, form }) => (
                                <InputGroup
                                  display={{ base: "none", md: "flex" }}
                                  borderRadius={"10px"}
                                  maxW={"300px"}
                                  size="md"
                                >
                                  <Input
                                    borderLeftRadius={"10px"}
                                    placeholder="Search products"
                                    onKeyDown={(event) => {
                                      if (
                                        event?.key === "Enter" &&
                                        values?.values?.searchQuery &&
                                        values?.values?.searchQuery !== ""
                                      ) {
                                        navigate(
                                          `/products/searchQuery=${values?.values?.searchQuery}`
                                        );
                                      }
                                    }}
                                    {...field}
                                  />
                                  <InputRightAddon
                                    onClick={() => {
                                      values?.values?.searchQuery &&
                                        values?.values?.searchQuery !== "" &&
                                        navigate(
                                          `/products/searchQuery=${values?.values?.searchQuery}`
                                        );
                                    }}
                                    cursor={"pointer"}
                                    borderRightRadius={"10px"}
                                    children={<BiSearch size="1.2rem" />}
                                  />
                                </InputGroup>
                              )}
                            </Field>
                          </Form>
                        );
                      }}
                    </Formik>
                  </MenuItem>
                </MenuList>
              </Menu> */}
                <Button
                  onClick={() => {
                    if (token) {
                      navigate("/cart");
                    } else {
                      setRedirectURL("/cart");
                      setOpenLogin(true);
                    }
                  }}
                  bg="transparent"
                  cursor={"pointer"}
                  // onClick={onOpen}
                  p={{ base: 0, sm: 1 }}
                  _active={{ bg: "transparent" }}
                  _hover={{ bg: "#f2cdcd" }}
                  // _focus={{ bg: "transparent" }}
                  rightIcon={
                    <Badge color="#FFF" px="3px" mb="15px" bgColor="#F55A5A">
                      {cartProducts?.length}
                    </Badge>
                  }
                >
                  <AiOutlineShoppingCart size={isSmallerThan440 ? 22 : 28} />
                </Button>
              </HStack>
              {token ? (
                <Menu>
                  <MenuButton
                    py={2}
                    transition="all 0.3s"
                    _focus={{ boxShadow: "none" }}
                  >
                    <HStack>
                      <Avatar
                        size={"sm"}
                        src={`${myBasicAccount?.data?.data?.user?.avatar?.url}`}
                      />
                      <VStack
                        display={{ base: "none", md: "flex" }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2"
                      >
                        <Text fontSize="sm" maxW="100px" isTruncated>
                          {myBasicAccount?.data?.data?.data?.name}
                        </Text>
                        <Text fontSize="xs" color="gray.600">
                          {myBasicAccount?.data?.data?.data?.role?.name}
                        </Text>
                      </VStack>
                      <Box display={{ base: "none", md: "flex" }}>
                        <FiChevronDown />
                      </Box>
                    </HStack>
                  </MenuButton>
                  <MenuList
                  // bg={useColorModeValue("white", "gray.900")}
                  // borderColor={useColorModeValue("gray.200", "gray.700")}
                  >
                    <Link to="/dashboard/data">
                      <MenuItem
                        display={token && type === "admin" ? "flex" : "none"}
                      >
                        Dashboard
                      </MenuItem>
                    </Link>
                    <MenuItem
                      onClick={() => {
                        setProfile(true);
                      }}
                    >
                      Profile
                    </MenuItem>
                    <Link to="/orders">
                      <MenuItem>Orders</MenuItem>
                    </Link>
                    <MenuItem
                      onClick={() => {
                        setPassChange(true);
                      }}
                    >
                      Change Password
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={onLogout}>Sign out</MenuItem>
                  </MenuList>
                </Menu>
              ) : (
                <LoginGradientButton
                  color="#FFF"
                  size={{ base: "sm", sm: "md", md: "md" }}
                  _hover={{ opacity: 0.9 }}
                  onClick={() => setOpenLogin(true)}
                >
                  Login
                </LoginGradientButton>
              )}
            </HStack>
          </Flex>
        </Nav>
        <Box overflow={"hidden"}>
          <Outlet />
        </Box>
      </Stack>
    </PageBg>
  );
};

export default MainNavigation;
